
<!-- conditional content projection - https://angular.io/guide/content-projection#conditional-content-projection-->

<header *ngIf="headerModalSlot?.templateRef || options?.headerTranslationKey || options?.header" class="kurz-modal-wrapper-header">

  <ng-template #optionsHeader>
    <h1 *ngIf="options?.headerTranslationKey">{{options?.headerTranslationKey | cxTranslate}}</h1>
    <h1 *ngIf="!options?.headerTranslationKey">{{options?.header}}</h1>
  </ng-template>

  <ng-container *ngIf="headerModalSlot?.templateRef; else optionsHeader">
    <ng-container *ngTemplateOutlet="headerModalSlot.templateRef"></ng-container>
  </ng-container>

  <ng-template #defaultAside>
    <aside class="kurz-modal-wrapper-aside">
      <app-icon-button
        iconType="CLOSE"
        colorTheme="BLACK"
        size="EXTRA_SMALL"
        (activateElement)="close()"
      ></app-icon-button>
    </aside>
  </ng-template>

  <ng-container *ngIf="asideModalSlot?.templateRef && (isCustomAsideVisible$ | async); else defaultAside">
    <aside class="kurz-modal-wrapper-aside">
      <ng-container *ngTemplateOutlet="asideModalSlot.templateRef"></ng-container>
    </aside>
  </ng-container>

</header>

<div *ngIf="headerModalSlot?.templateRef || options?.headerTranslationKey || options?.header" class="kurz-modal-wrapper-separator"></div>

<header *ngIf="kurzInformationHeaderModalSlot?.templateRef" class="kurz-modal-wrapper-information-header">

  <app-icon
    iconType="INFO"
    iconSize="SMALL"
  ></app-icon>

  <h1>{{ 'kurzModalWrapper.information' | cxTranslate}}</h1>

  <aside class="kurz-modal-wrapper-aside">
    <app-icon-button
      iconType="CLOSE"
      [colorTheme]="['SECONDARY', 'NONE', {'colorThemeBorderColor': 'transparent'}]"
      size="EXTRA_SMALL"
      (activateElement)="close()"
    ></app-icon-button>
  </aside>

</header>

<ng-container *ngIf="kurzFreeBodyLayoutModalSlot?.templateRef">
  <ng-container *ngTemplateOutlet="kurzFreeBodyLayoutModalSlot.templateRef"></ng-container>
</ng-container>

<section *ngIf="kurzContextModalSlot?.templateRef && (isKurzContextVisible$ | async)" class="kurz-modal-wrapper-context">
  <ng-container *ngTemplateOutlet="kurzContextModalSlot.templateRef"></ng-container>
</section>

<section *ngIf="contentModalSlot?.templateRef && !scrollableContentModalSlot?.templateRef" class="kurz-modal-wrapper-section">
  <ng-container *ngTemplateOutlet="contentModalSlot.templateRef"></ng-container>
</section>

<section *ngIf="scrollableContentModalSlot?.templateRef && !contentModalSlot?.templateRef" class="kurz-modal-wrapper-scrollable-section">
  <ng-container *ngTemplateOutlet="scrollableContentModalSlot.templateRef"></ng-container>
</section>

<section *ngIf="contentModalSlot?.templateRef && scrollableContentModalSlot?.templateRef" class="kurz-modal-wrapper-section">
  <div style="color:red;">scrollableContent and content modalSlot cannot be used in the same modal</div>
</section>

<footer *ngIf="footerModalSlot?.templateRef" class="kurz-modal-wrapper-footer">
  <ng-container *ngTemplateOutlet="footerModalSlot.templateRef"></ng-container>
</footer>

import { Component, OnInit } from '@angular/core';
import { ModalSlotDirective } from '@util/components/util-modal/standardized-modal/modal-slot.directive';
import { StandardizedModalComponent } from '@util/components/util-modal/standardized-modal/standardized-modal.component';
import { BehaviorSubject } from 'rxjs';


export enum KurzModalSlotType {
  kurzContext = 'kurzContext',
  kurzFreeBodyLayout = 'kurzFreeBodyLayout',
  kurzInformationHeader = 'kurzInformationHeader'
}


@Component({
  selector: 'app-kurz-modal-wrapper',
  templateUrl: './kurz-modal-wrapper.component.html',
  styleUrls: ['./kurz-modal-wrapper.component.scss']
})
export class KurzModalWrapperComponent extends StandardizedModalComponent implements OnInit {

  isKurzContextVisible$ = new BehaviorSubject<boolean>(true);
  isCustomAsideVisible$ = new BehaviorSubject<boolean>(true);

  kurzContextModalSlot: ModalSlotDirective;
  kurzFreeBodyLayoutModalSlot: ModalSlotDirective;
  kurzInformationHeaderModalSlot: ModalSlotDirective;

  ngOnInit(): void {
    this.unknownModalSlotHandler = this.kurzModalSlotHandler;
  }

  kurzModalSlotHandler = (slotType: KurzModalSlotType, slot?: ModalSlotDirective) => {
    switch(slotType) {
      case KurzModalSlotType.kurzContext: this.kurzContextModalSlot = slot; break;
      case KurzModalSlotType.kurzFreeBodyLayout: this.kurzFreeBodyLayoutModalSlot = slot; break;
      case KurzModalSlotType.kurzInformationHeader: this.kurzInformationHeaderModalSlot = slot; break;
    }
  };

}
